import * as React from 'react'
import { useEffect } from 'react';
import { getTheme } from '../functions/theme';

const Redirect = (props: {
    show: boolean,
    setShow: React.Dispatch<React.SetStateAction<boolean>>,
    link: string,
}) => {

    const currentTheme: 'light' | 'dark' = getTheme();

    const keyDownCloseRedirect = (e: any) => {
        if ((e.charCode || e.keyCode) === 27) {
            props?.setShow(false);
        }
    }

    useEffect(() => {

        if (props.show) {
            document.body.style.overflow = 'hidden';
        }
        document.body.addEventListener('keydown', keyDownCloseRedirect)
        return function cleanup() {
            document.body.removeEventListener('keydown', keyDownCloseRedirect)
            //unlock scroll
            document.body.style.overflow = 'unset';
        }

    })

    if (!props.show) {
        return null
    }

    return (
        <div className={`fixed z-50 h-screen items-center justify-center w-screen flex flex-col ${currentTheme === 'light' ? 'bg-cardinal-white text-black' : 'bg-black text-cardinal-white'}`}>
            <div className='flex font-bold'>You're being redirected to {props?.link && props.link}, is it ok?</div>
            <a className={`font-bold transition-all flex items-center mt-2 border-2 justify-center h-10 uppercase text-xs w-40 rounded-full lg:w-48 lg:text-sm
                ${currentTheme == 'light' ? 'hover:bg-black text-black hover:text-cardinal-white border-black' 
                                        : 'hover:bg-cardinal-white border-cardinal-white text-cardinal-white hover:text-black'}`}
                href={props?.link && props.link} target={"_blank"}>
                Ok!
            </a>
            <div className={`font-bold transition-all flex items-center border-2 mt-2 justify-center h-10 uppercase text-xs w-40 rounded-full lg:w-48 lg:text-sm cursor-pointer
                ${currentTheme == 'light' ? 'hover:bg-black text-black hover:text-cardinal-white border-black' 
                                        : 'hover:bg-cardinal-white border-cardinal-white text-cardinal-white hover:text-black'}`}
                onClick={() => props?.setShow(false)}>
                No thank you
            </div>
        </div>
    )

}

export default Redirect;