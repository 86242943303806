import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { getTheme } from "../functions/theme";
import { getSocialLinkBySocialName } from "../functions/utils";

const SocialLinks = () => {
  const staticQuery: any = useStaticQuery(query);
  const logos: any = staticQuery.allFile.edges.filter((el: any) => (el.node.relativePath as string).includes('socials/') || null);
  const lightThemelogos: any = logos.filter((el: any) => (el.node.relativePath as string).includes('light') || null);
  // const darkThemelogos: any = logos.filter((el: any) => (el.node.relativePath as string).includes('dark') || null);

  // const currentTheme: 'light' | 'dark' = getTheme();

  // xs: w - 2 / 5
  return (
    <div className="flex flex-row items-end w-full h-7 space-x-11 md:space-x-20 md:w-auto">
      { lightThemelogos.map((el: any, index:number) => {
          const image: IGatsbyImageData = el?.node && getImage(el.node);
          const href: string = getSocialLinkBySocialName(el.node.relativePath);

          return (
            <div className="flex h-full" key={el.node.id}>
              <a href={href} target="_blank" className="flex flex-row h-full space-x-2 text-base underline md:no-underline md:text-2xl md:space-x-3">
                {(index == 0) && 
                  <>
                    <div className="flex items-center justify-center h-full pb-1 aspect-square">
                      <GatsbyImage image={image} alt={'test'} />
                    </div>
                    <span className="flex items-end undeline-animation">Instagram</span>
                  </>
                }
                {(index == 1) && 
                  <>
                    <div className="flex items-center justify-center h-full pb-2 aspect-square">
                      <GatsbyImage image={image} alt={'test'} />
                    </div>
                    <span className="flex items-end undeline-animation">Linkedin</span>
                  </>
                }
              </a>
            </div>
          )
        })
      }
    </div>
  )

}

export const query = graphql`
  query {
    allFile(filter: {internal: {mediaType: {regex: "/image/"}}, extension: {ne: "svg"}}) {
      edges {
        node {
          id
          relativePath
          extension
          childImageSharp {
            id
            gatsbyImageData(layout: FIXED, width: 24, aspectRatio: 1)
          }
        }
      }
    }
  }
`;

export default SocialLinks;