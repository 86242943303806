import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { useState, useEffect } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { getTheme } from "../functions/theme";
import MenuNavIcon from "./menu-nav-icon";
import MenuNavIcon2 from "./menu-icon-2";
import MenuModal from "../modals/menu-modals";
import { LottiePlayer } from "lottie-react";
import { isRunningOnWeb } from "../functions/utils";
gsap.registerPlugin(ScrollTrigger);

const Header = (props: { 
  currentPageTheme: string,
}) => {

  const staticQuery: any = useStaticQuery(query);
  const logos: any[] = staticQuery.allFile.edges.filter((el: any) => (el.node.relativePath as string).includes('logo/') || null);
  const whitelogo: any = logos.filter((el: any) => (el.node.relativePath as string).includes('cardinal-white') || null)[0]
  const blacklogo: any = logos.filter((el: any) => (el.node.relativePath as string).includes('cardinal-black') || null)[0]
  const whiteImage: IGatsbyImageData = whitelogo?.node && getImage(whitelogo.node);
  const blackImage: IGatsbyImageData = blacklogo?.node && getImage(blacklogo.node);

  const currentTheme: 'light' | 'dark' = getTheme();

  // State for menu modal ---
  const [show, setShow] = useState<boolean>(false);
  // State for logo colors ---
  const [currentLogoStyle, setLogoStyle] = useState<string>('black');

  // const [currentLogo, setLogo] = useState(currentTheme == 'light' ? blackImage : whitelogo);

  //ci deve essere un modo migliore di fare sta cosa ma non va mai niente su sto coso
  useEffect(() => {
    // setLogo(whitelogo);
    // console.log("currentLogo", currentLogo);

    //Aggiunto controllo del tema della pagina corrente (es. About)
    if (currentTheme == 'dark' || (props?.currentPageTheme && props.currentPageTheme == 'dark')) {
      setLogoStyle('white')
    }

    /**
     * Inizio logica header
     */
    let header: any = document.getElementById("app-header");
    if (header) {
      header.classList.add('absolute');
    }
    const hideHeaderAction: gsap.core.Tween = gsap.to(".animatedHeader", { y: '-=200', duration: 0.5, ease: 'power2.in', paused: true });

    ScrollTrigger.create({
      trigger: "#app-header",
      start: "100px top",
      end: 99999,
      toggleActions: "restart pause restart pause",
      onUpdate: ({ progress, direction, isActive }) => {
        if (direction == -1) { //verso l'alto mostro e setto fixed
          hideHeaderAction.reverse()
          if (header) {
            header.classList.remove('absolute');
            header.classList.add('fixed');
          }
        }
        if (direction == 1) { //verso il basso nascondo
          hideHeaderAction.play();
        }
      },
      onLeaveBack: () => {
        //tolgo la classe static dall'header quando torna su del tutto così torna nell pagina
        if (header) {
          header.classList.remove('fixed');
          header.classList.add('absolute');
        }

      }
    });
    /**
     * Fine logica header
     */

    //Aggiunto ulteriore controllo del tema relativo alla pagina corrente
    if (currentTheme == 'light' && (props?.currentPageTheme && props.currentPageTheme !== 'dark')) {

      // Aggiunto ulteriore controllo della pagina corrente
      const page: string = window.location.href;   
      if (!page.includes('manifest')) {
      /**
      * Inizio logica cambio colore al raggiungimento di determinati trigger
      */
        ScrollTrigger.create({
          trigger: "#reverse-color-zone-trigger",
          start: 'top',
          // markers: true,
          onUpdate: ({ progress, direction, isActive }) => {
            // console.log('onUpdate', 'reverse-color-zone-trigger', progress, direction, isActive);
            // console.log('Logo in bianco');
            setLogoStyle('white');
            // setLogo(whiteImage)
          },
          onLeaveBack: ({ progress, direction, isActive }) => {
            // console.log('onLeaveBack', 'reverse-color-zone-trigger', progress, direction, isActive);
            // console.log('Logo in nero');
            setLogoStyle('black');
            // setLogo(blackImage)
          },
          onEnter: ({ progress, direction, isActive }) => { }
        });

        ScrollTrigger.create({
          trigger: "#reverse-bg-zone-trigger",
          start: 'end',
          // markers: true,
          onUpdate: ({ progress, direction, isActive }) => {
            // console.log('onUpdate', 'reverse-bg-zone-trigger', progress, direction, isActive);
            // console.log('Logo in bianco');
            if (progress > 0.8) {
              changeColor('reverse-color-bg', 'black');
              // setLogo(whiteImage)
              setLogoStyle('white');
            } else {
              changeColor('reverse-color-bg', 'white');
              // setLogo(blackImage)
              setLogoStyle('black');
            }
          },
          onLeaveBack: ({ progress, direction, isActive }) => {
            // console.log('onLeaveBack', 'reverse-bg-zone-trigger', progress, direction, isActive);
          },
          onEnter: ({ progress, direction, isActive }) => { }
        });
      }
    }

    function changeColor(element: 'logo' | 'reverse-color-bg', goTo: 'white' | 'black') {
      if (element == 'reverse-color-bg') {
        const whiteZone = document.getElementById('reverse-bg-zone-trigger');
        const darkZone = document.getElementById('reverse-color-zone-trigger');
        const header = document.getElementById('app-header');
        if (goTo == 'white') {
          whiteZone?.classList.remove('reverse-bg');
          darkZone?.classList.add('reverse-bg');
          header?.classList.remove('reverse-bg');
        } else {
          whiteZone?.classList.add('reverse-bg');
          darkZone?.classList.remove('reverse-bg');
          header?.classList.add('reverse-bg');
        }
      } else if (element == 'logo') {
        setLogoStyle(goTo);
        // const logoElement = document.getElementById('logo-svg');
        // if (goTo == 'white') {
        //   logoElement?.classList.add('reverse');
        // } else {
        //   logoElement?.classList.remove('reverse');
        // }
      }
    }
  }, [])


  function callModal(state: any) {
    if (isRunningOnWeb()) {
      window.dispatchEvent(new CustomEvent("modal-handler", { detail: { state } }));
    }
  }

  return (
    <>
      <div className={`flex z-40 flex-row items-center w-full lg:h-24 h-16 max-w-full animatedHeader ${((props?.currentPageTheme && props.currentPageTheme == 'dark') || currentTheme == 'dark') ? 'bg-black/[0.7]' : 'reverse-bg-header-trigger'}`} id="app-header">
        <div className="fixed flex justify-center w-full">
          <span className="">
            <Link to="/">
              {/* <span>LOGO</span> */}
              {/* <StaticImage src="../images/logo/logo cardinal.svg" width={120} placeholder="tracedSVG" quality={100} alt="head-logo"></StaticImage>  */}
              {/*  <div className="w-48"><GatsbyImage image={currentLogo} alt={'test'} className="w-full h-full" /></div>  */}
              {(currentLogoStyle == 'white' || show === true) && <div className="lg:w-56 md:w-48 w-36"><GatsbyImage image={whiteImage} alt={'test'} className="w-full h-full" /></div>}
              {(currentLogoStyle == 'black') && <div className="lg:w-56 md:w-48 w-36"><GatsbyImage image={blackImage} alt={'test'} className="w-full h-full" /></div>}
              {/* <GatsbyImage image={_image} alt={'test'} className="w-full h-full" /> */}
            </Link>
          </span>
        </div>
        <div className="absolute top-0 right-0 flex flex-row items-center justify-end h-full w-min">
          {/* {show === false &&
            <div className={`flex-row items-center hidden md:flex  ${currentLogoStyle == 'white' && 'text-white'}`}>
              <span className="pr-4 lg:pr-16 undeline-animation">
                <Link to="/projects">
                  Projects
                </Link>
              </span>
              <span className="pr-4 lg:pr-20 undeline-animation">
                <Link to="/about">
                  About
                </Link>
              </span>
            </div>
          }  */}
          {/* {show === true &&
            <div className="flex-row items-center hidden text-white md:flex">
              <span className="pr-4 lg:pr-16">
                CLOSE
              </span>
            </div>
          }

          <div className="">
            <div className="w-20" >
              { /* <MenuNavIcon 
                onClick={() => (show === false) ? setShow(true) : setShow(false)} 
                onClose={() => setShow(false)}
               currentLogoStyle={currentLogoStyle} 
              />  */
                /* <MenuNavIcon 
                  onClick={() => {
                    LottiePlayer.setDirection(-1)
                    LottiePlayer.play();
                  }} 
                  onClose={() => setShow(false)}
                   currentLogoStyle={currentLogoStyle} 
                /> */
              }
             {/*  <MenuNavIcon2 show={setShow} currentLogoStyle={currentLogoStyle} key={32} /> */}
            </div>
          </div>
      <div>
      </div>
     {/*  <div>
        {<MenuModal key={31} show={show} onClose={() => setShow(false)}></MenuModal>}
      </div> */}
    </>
  )
}

export const query = graphql`
    query {
      allFile(filter: {internal: {mediaType: {regex: "/image/"}}, extension: {ne: "svg"}}) {
      edges {
        node {
          id
          relativePath
          extension
          childImageSharp {
            id
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
  }
}
`;

export default Header