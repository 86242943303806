import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import React, { useState } from "react"
import { isMobile } from "../functions/theme"
import { getImageFolder } from "../functions/utils"
import Redirect from "./redirect"
import SocialLinks from "./social-links"

const Footer = () => {

  const _isMobile: boolean = isMobile();

  const staticQuery: any = useStaticQuery(query);

  const _btnText: { buttonLabel: string, buttonLink: string } = staticQuery.strapiComponentHomeContactArea;
  const _footerText: {
    address: string,
    backOnTopText: string,
    capTable: string,
    pIva: string,
    companyName: string,
    mail: string,
    phoneNumber: string,
  } = staticQuery.strapiFooter;

  const [btnText, setBtnText] = useState<{buttonLabel: string, buttonLink: string}>(_btnText);
  const [footerText, setFooterText] = useState<{
                                                address: string,
                                                backOnTopText: string,
                                                capTable: string,
                                                pIva: string,
                                                companyName: string,
                                                mail: string,
                                                phoneNumber: string,
                                              }>(_footerText);
  const [showRedirect, setShowRedirect] = useState<boolean>(false);

  const backgroundsFooter: any[] = getImageFolder('backgrounds/');
  const desktopBg: any = backgroundsFooter.filter((el: any) => (el.node.relativePath as string).includes('footer-desktop') || null)[0];
  const mobileBg: any = backgroundsFooter.filter((el: any) => (el.node.relativePath as string).includes('footer-mobile') || null)[0];
  const desktop: IGatsbyImageData = desktopBg?.node && getImage(desktopBg.node);
  const mobile: IGatsbyImageData = mobileBg?.node && getImage(mobileBg.node);

  const background: IGatsbyImageData = _isMobile ? mobile : desktop;

  return (
    <>
      <Redirect show={showRedirect} setShow={setShowRedirect} link='https://form.questionscout.com/6356a446efdc2d7e0c5e5266'/>
      <div className="flex bg-white">
        <div className="absolute z-0 flex justify-end w-full 3xl:h-[41rem]">
          <GatsbyImage image={background} alt={'FOOTER'}/>
        </div>
        <div className="z-10 flex flex-col w-full px-6 pt-12 pb-6 xl:pb-0 h-min lg:px-32 md:px-16 xl:pt-40 3xl:pt-56">
          {/*  <div className="flex-col w-full my-20">
            <InfiniteTicker labels={['Let’s keep in touch!', 'Let’s keep in touch!']} labelClasses={'3xl:text-9xl xl:text-7xl text-4xl text-white text-stroke-black'}></InfiniteTicker>
            <InfiniteTicker labels={['Let’s keep in touch', 'Let’s keep in touch', 'Let’s keep in touch']} labelClasses={'3xl:text-9xl xl:text-7xl text-4xl text-black'} orientation="to-right"></InfiniteTicker> 
          </div> */}
          {/* <div className="flex items-center justify-center flex-grow w-full my-auto">
            <a href={btnText?.buttonLink} target={"_blank"}><span className="pill-button large btn-like">{btnText?.buttonLabel}</span></a>
          </div> */}
          <div className="lg:w-1/2 ">
            <div className="w-[97%] text-5xl xl:w-4/5 3xl:w-2/3 font-bold hover:text-cardinal lg:text-7xl xl:text-8xl">
              <div onClick={() => setShowRedirect(true)} className='cursor-pointer'>Let’s keep in touch</div>
              {/* <a href={btnText?.buttonLink} target={"_blank"}>Let’s keep in touch</a> */}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:pt-14 xl:pb-20 3xl:pb-28 lg:space-x-12 xl:space-x-16">
            <div className="flex flex-col h-full py-16 lg:py-0">
              <span className="pb-3 text-xs font-semibold uppercase md:text-sm lg:text-lg xl:pb-6">SOCIALS</span>
              <SocialLinks/>
            </div>
            <div className="flex flex-col h-full pb-5 lg:pb-0">
              <span className="pb-3 text-xs font-semibold uppercase lg:text-lg md:text-sm xl:pb-6">Alfercom s.r.l.</span>
              <div className="flex flex-row text-xs font-medium space-x-7 xl:space-x-20 md:text-lg xl:text-xl">
                <span>{footerText?.capTable}</span>
              </div>
            </div>
            <div className="flex flex-col h-full pb-5 lg:pb-0">
              <span className="pb-3 text-xs font-semibold uppercase lg:text-lg md:text-sm xl:pb-6">VAT</span>
              <div className="flex flex-row text-xs font-medium space-x-7 xl:space-x-20 md:text-lg xl:text-xl">
                <span>{footerText?.pIva}</span>
              </div>
            </div>
            {/* <span className="text-xl uppercase">{`${footerText?.companyName} ${footerText?.capTable}`}</span> */}
            <div className="flex flex-col text-xs font-medium md:text-lg xl:text-2xl">
              <span className="pb-2 lg:pb-4">Cardinal.solar | All rights reserved</span>
              <span>{footerText?.address}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

export default Footer

export const query = graphql`
  query {
    strapiComponentHomeContactArea {
      buttonLink
      buttonLabel
    }
    strapiFooter {
      address
      backOnTopText
      capTable
      pIva
      companyName
      mail
      phoneNumber
    }
  }
`;